@import url("https://fonts.googleapis.com/css?family=Open+Sans:400,600,700,800&display=swap");

html {
    font-size: 62.5%;
    background-color: #14171b;
}

body {
    height: 100%;
    overflow-x: hidden;

    img {
        display: block;
        width: 100%;
    }

    a {
        text-decoration: none;

        &:hover {
            text-decoration: none;
        }
    }

    ul {
        list-style-type: none;
    }
}

#root {
    background-color: #14171b;
    font-family: "Open Sans",
        sans-serif;
    font-size: 16px;
    font-weight: 600;
    color: #8e8e8e;
    letter-spacing: 0.5px;
    line-height: 1.3;
    height: 100%;
}