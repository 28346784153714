@import '../../assets/scss/variables.scss';
@import '../../assets/scss/mixins.scss';

.footer {
    text-align: center;
    margin-top: 10rem;

    &__copyright {
        font-size: 10px;
        font-weight: 400;
        color: $clr-mainGreyText;
        text-align: center;
        margin-bottom: 1.6rem;

        @media screen and (min-width: 768px) {
            font-size: 12px;
        }

        a {
            text-decoration: none;
            color: $clr-mainGreyText;
            transition: color 0.4s;

            &:hover {
                color: $clr-accent;
            }
        }
    }
}