// ******* Header *******
$clr-headerBg: #14171b;
$clr-headerHamburger: #47c2ff;
$clr-mainGreyText: #b8b8b8;
$clr-slideoutMenuIcons: #1a1e22;

// ******* Mobile Menu
$clr-mobileMenu: #121518;
$clr-mobileMenuSocials: #b1d8e5;
$clr-mobileMenuNameText: #9d9d9d;
$clr-mobileMenuLogo: #9d9d9d;

// ******* Profile
$clr-profileNameText: #9d9d9d;
$clr-profileOccText: #47c2ff;
$clr-profileDescText: #8e8e8e;

// ******* Projects
$clr-projectsOverlay: #14171b;

$clr-accent: #47c2ff;